import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CatalogosService } from 'src/app/services/catalogos/catalogos.service';
import { AwsService, CreditosService, RolesService } from 'src/app/services/service.index';
import swal2 from 'sweetalert2';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css'; // optional for styling
import { createPopper } from '@popperjs/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import Swal from 'sweetalert2';
import Swal2 from 'sweetalert2';
import { Subject } from 'rxjs';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-cotizador',
  templateUrl: './cotizador.component.html',
  styleUrls: ['./cotizador.component.css']
})
export class CotizadorComponent implements OnInit {
  @ViewChild('dt') dt: Table | undefined;
  plazosSeleccionados = [];
  doctores = [];
  productos = [];
  paymentPeriods = [];
  producto = {};
  generico = true;
  input = false;
  contacto = false;
  terms = [];
  max = 5;
  porcentaje = false;
  primerPago = false;
  pagoInicial = 0;
  doctorSeleccionado = {user_id: '', name: '', mail: ''};
  valorResidual = 1;
  articulos = [];
  cols = [
    {field: 'product_key', header: 'Clave producto'},
    {field: 'product_number', header: 'Número producto'},
    {field: 'product_type', header: 'Tipo producto'},
    {field: 'short_description', header: 'Descripción corta'},
    {field: 'brand', header: 'Marca'},
    {field: 'model', header: 'Modelo'},
    {field: 'quantity', header: 'Cantidad'},
    {field: 'iva_price', header: 'Subtotal'},
    {field: 'total', header: 'Total'},
  ]

  columns = [
    {field: 'product_key', header: 'Clave producto'},
    {field: 'product_number', header: 'Número producto'},
    {field: 'product_type', header: 'Tipo producto'},
    {field: 'short_description', header: 'Descripción corta'},
    {field: 'brand', header: 'Marca'},
    {field: 'model', header: 'Modelo'},
    {field: 'iva_price', header: 'Precio con IVA'},
    {field: 'quantity', header: 'Cantidad'},
  ]
  total = 0;
  
  simulacion = [];
  productoSeleccionado: any;
  showmodalds = false;
  iva = '16';
  rate = '28';
  amortizacion: any[];
  showModalAmortizacion = false;
  costoConsulta;
  pacientesPorMes;
  // quantity = 1;
  product: FormGroup;

  searchTerm$ = new Subject<string>();
  listFiltered = [];
  mostrarFormulario = false;
  mostrarTabla = true;
  roles = [];
  companies = [];
  superAdmin = false;
  company_id = '';

  constructor(private catalogosService: CatalogosService,
              private creditosService: CreditosService,
              private sanitizer: DomSanitizer,
              private rolesService: RolesService,
              private awsService: AwsService) { }

  ngOnInit(): void {
    this.inicializarProduct();

    this.rolesService.getRoles().subscribe(resp => {
      this.roles = resp;
      if(this.roles.find(r => r.name == 'SuperAdmin').id == localStorage.getItem('rid')){
        this.awsService.getCompanyes().subscribe(resp => {
          this.companies = resp;
          this.company_id = this.companies[0].id;
          this.superAdmin = true;
        });
      }
    })

    this.catalogosService.getDoctoresPorVendedor(localStorage.getItem('uid')).subscribe(resp => {
      this.doctores = resp;
    })

    this.catalogosService.getProductos().subscribe(resp => {
      this.productos = [];
      resp.forEach(a => {
        a['quantity'] = 1;
        a.price = this.dosDecimales(a.price);
        a.iva_price = this.dosDecimales(a.iva_price);
        this.productos.push(a);
      })
      // this.productos = resp;
    })
    this.creditosService.getCondiciones().subscribe(resp => {
      this.terms = this.amortizacion = resp.sort(((a, b) => a.value - b.value));;
      this.plazosSeleccionados = [];
      this.terms.forEach(t => {
        this.plazosSeleccionados.push(t.value);
      })
    })
    this.creditosService.getperiodosPagos().subscribe(resp => {
      resp.forEach(p => {
        this.paymentPeriods.push(p.attributes);
      })
    })

    this.filterList();
  }

  dosDecimales(n) {
    let t=n.toString();
    let regex=/(\d*.\d{0,2})/;
    return t.match(regex)[0];
  }

  removeAccents(str){
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
  } 

  filterList(): void {
    if(this.searchTerm$){
      this.searchTerm$.subscribe(term => {
        this.listFiltered = this.productos
          .filter(item => this.removeAccents(item.short_description).includes(this.removeAccents(term)) );
      });
    }
  }

  inicializarProduct(){
    this.product = new FormGroup({
      product_type: new FormControl( null, Validators.required),
      short_description: new FormControl( null, Validators.required),
      detailed_description: new FormControl( null, Validators.required),
      product_key: new FormControl( null, Validators.required),
      product_number: new FormControl( null, Validators.required),
      brand: new FormControl( null, Validators.required),
      model: new FormControl( null, Validators.required),
      price: new FormControl( null, Validators.required),
      url: new FormControl( null ),
      image: new FormControl( null ),
      quantity: new FormControl( 1 ),
  
      created_at: new FormControl( null ),
      extra1: new FormControl( null ),
      extra2: new FormControl( null ),
      extra3: new FormControl( null ),
      id: new FormControl( null ),
      iva_price: new FormControl( null ),
      updated_at: new FormControl( null ),
      unitary_value: new FormControl( null ),
  
    });

    this.producto = {
      brand: null,
      created_at: null,
      detailed_description: null,
      extra1: null,
      extra2: null,
      extra3: null,
      id: null,
      image: null,
      price: null,
      model: null,
      iva_price: null,
      product_key: null,
      product_number: null,
      product_type: null,
      short_description: null,
      updated_at: null,
      url: null,
    }
  }

  changePI(){
    this.porcentaje = !this.porcentaje;
  }

  changePP(){
    this.primerPago = !this.primerPago;
  }

  seleccionarRemitente(opcion){
    switch(opcion){
      case 'generico':
        this.generico = !this.generico;
        this.input = false;
        this.contacto = false;
        break;
      case 'input':
        this.input = !this.input;
        this.generico = false;
        this.contacto = false;
        break;
      case 'contacto':
        this.contacto = !this.contacto;
        this.generico = false;
        this.input = false;
        break;
      default:
        break;
    }
  }

  detalleProducto(rowData){
    this.productoSeleccionado = rowData;
    this.showmodalds = true;
  }

  hideds(){
    this.showmodalds = false;
  }

  cambiarPagoInicial(){
    if(this.simulacion.length > 0){
      this.enviarCotizacion('SI');
    }
  }

  seleccionarPlazo(plazo, group){
    if(!this.plazosSeleccionados.includes(plazo)){
        this.plazosSeleccionados.push(plazo);
    }else{
      var indice = this.plazosSeleccionados.indexOf(plazo);
      this.plazosSeleccionados.splice(indice, 1);
    }
  }

  seleccionarProducto(producto){
    this.producto = this.productos.find(p => p.id == producto);
    console.log(this.producto)
    if(this.producto['iva_price'].split('.')[1].length == 1){
      this.producto['iva_price'] = this.producto['iva_price'] + '0';
    }
    Object.keys(this.producto).forEach(k => {
      this.product.patchValue({[k]: this.producto[k]})
    });
    this.product.patchValue({iva_price: this.formatCurrency(this.product.value.iva_price)})
  }

  seleccionarMedico(medico){
    this.doctorSeleccionado = this.doctores.find(d => d.id == medico);
    this.costoConsulta = this.doctorSeleccionado['fee']
    this.pacientesPorMes = this.doctorSeleccionado['month_patients']
  }

  setCurrency(numero, opcion){
    switch(opcion){
      case 1:
        var input = (document.getElementById('iva_price') as HTMLInputElement);
        this.product.patchValue({iva_price: this.formatCurrency(numero)})
        break;
      case 2:
        var input = (document.getElementById('pi') as HTMLInputElement);
        break;
    }
    input.value = this.formatCurrency(numero);
  }

  formatCurrency(numero){
    var respuesta = numero.replace(/\D/g, "")
    .replace(/([0-9])([0-9]{2})$/, '$1.$2')
    .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ",");
    return respuesta;
    // return numero.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

  multiplicacionArticulos(precio, cantidad){
    precio = precio.replaceAll(',', '');
    precio = precio.replaceAll('.', '');
    precio = precio * cantidad;
    return precio.toString();
  }

  agregarArticuloCotizacion(){
    if(this.product.valid){
      var producto = this.product.value;
      var cantidad = this.product.value.quantity;
      producto['quantity'] = cantidad;
      producto['total'] = this.formatCurrency(this.multiplicacionArticulos(producto.iva_price, cantidad));
      producto['iva_price'] = this.formatCurrency(producto['iva_price']);
      this.articulos.push(producto);
      if(producto.id){
        this.productos.find(p => p.id == producto.id).quantity = '1';
      }
      this.total = 0;
      this.articulos.forEach(a => {
        var ip = a.total.replaceAll(',', '');
        this.total = this.total + parseFloat(ip)
      });
      (document.getElementById('productForm') as HTMLFormElement).reset();
      if(this.mostrarTabla){
        this.dt.filterGlobal('', 'contains')
      }
      // (document.getElementById('quantity') as HTMLInputElement).value = '1';
      // this.quantity = 1;
      this.inicializarProduct();
      this.mostrarFormulario = false;
      this.mostrarTabla = true;
    }else{
      //benja was here
      console.log('invalido');
      //this.mostrarFormulario = true;
      swal2.fire('Primero debe seleccionar un producto o completar la informacion del seleccionado','','info');
    }
  }

  quantityChange(id, suma){
    var articulo = this.productos.find(p => p.id == id);
    if(suma == -1 && articulo['quantity'] == '1'){
      return;
    }
    articulo['quantity'] = (parseInt(articulo['quantity']) + suma).toString();
  }

  editarArticulo(id){
    this.mostrarFormulario = true;
    this.seleccionarProducto(id);
  }

  articuloNuevo(){
    this.inicializarProduct();
    this.mostrarFormulario = true;
    this.mostrarTabla = false;
  }

  borrar(i){
    this.articulos.splice(i, 1);
  }

  cambioInicialMinimo(){
   // this.agregarArticuloCotizacion();
    if(this.total > 0){
      if(parseFloat(this.getFormattedValue(this.pagoInicial.toString())) > (this.total/2)){
        swal2.fire('Atención', 'El pago mínimo no debe ser mayor al 50% del total de la cotización', 'info');
        this.pagoInicial = 0;
        this.valorResidual = 1;
      }
    }else{
      swal2.fire('Atención', 'No hay artículos en la cotización', 'info');
      this.pagoInicial = 0;
    }
  }

  

  show(tabla) {
    this.amortizacion = [];
    this.amortizacion = tabla.sort(((a, b) => a.pay_number - b.pay_number));
    this.showModalAmortizacion = true; // Show-Hide Modal Check
  }

  hideModal() {
    this.showModalAmortizacion = false;
  } 

  tooltip(e, ruta, url){
    // console.log(e, ruta);

    /* // Pass the button, the tooltip, and some options, and Popper will do the
    // magic positioning for you:
    var tooltip = document.createElement('img');
    tooltip.src = ruta;
    createPopper(e, tooltip, {
      placement: 'right',
    }); */
    tippy(e, {
      content: `<div>
                  <img style="max-width:400px;" src=${ruta}>
                </div>`,
      placement: 'right',
      duration: 0,
      allowHTML: true,
    });
  }

  actualizarArticulo(){
    if(this.product.valid){
      if(this.product.value.id == null){
        swal2.fire('Atención', 'No existe un artículo con esas características, te recomendamos guardarlo como nuevo', 'info');
        return;
      }
      this.product.value.iva_price = this.product.value.iva_price.replaceAll(',', '');
      this.catalogosService.actualizaProducto(this.product.value.id, this.product.value).subscribe( np => {
        var nuevoProducto = np['data']['attributes'];
        swal2.close();
        const index = this.productos.findIndex(a => a.id == this.product.value.id);
        if (index >= 0) {
          this.productos.splice(index, 1);
        }
        if(nuevoProducto.iva_price.split('.')[1].length == 1){
          nuevoProducto.iva_price = nuevoProducto.iva_price.replaceAll(',', '')
        }
        this.productos.push(nuevoProducto);
        swal2.fire({
          title: 'Atención',
          text: '¿Desea agregar el artículo a la cotización?',
          icon: 'success',
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Si',
          cancelButtonText: 'No',
          allowOutsideClick: false
        }). then ( res => {
          if ( res.value ) {
            Object.keys(nuevoProducto).forEach(k => {
              this.product.value[k] = nuevoProducto[k];
            })
            this.agregarArticuloCotizacion();
          }
  
        } );
  
      }, (err) => {
        swal2.close();
        console.log(err);
        swal2.fire({
          title: 'Ocurrio un error',
          text: '',
          icon: 'info',
          showConfirmButton: true,
          showCancelButton: false,
          allowOutsideClick: false
        }). then ( res => {
          if ( res.value ) {
            this.catalogosService.getProductos().subscribe(resp => {
              this.productos = resp;
              this.mostrarFormulario = false;
            })
          }
        } );
       } );
    }else{
      swal2.fire('Atención', 'Faltan datos', 'info');
    }
  }

  nuevoArticulo(){
    this.product.value.iva_price = this.product.value.iva_price.replaceAll(',', '')
    this.catalogosService.crearProducto(this.product.value).subscribe( np => {
      var nuevoProducto = np['data']['attributes'];
      if(nuevoProducto.iva_price.split('.')[1].length == 1){
        nuevoProducto.iva_price = nuevoProducto.iva_price.replaceAll(',', '')
      }
      this.productos.push(nuevoProducto);
      swal2.close();
      swal2.fire({
        title: 'Atención',
        text: '¿Desea agregar el artículo a la cotización?',
        icon: 'success',
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
        allowOutsideClick: false
      }). then ( res => {
        if ( res.value ) {
          Object.keys(nuevoProducto).forEach(k => {
            this.product.value[k] = nuevoProducto[k];
          })
          this.agregarArticuloCotizacion();
        }

      } );

    }, (err) => {
      swal2.close();
      console.log(err);
      swal2.fire({
        title: 'Ocurrio un error',
        text: '',
        icon: 'info',
        showConfirmButton: true,
        showCancelButton: false,
        allowOutsideClick: false
      }). then ( res => {
        if ( res.value ) {
          this.ngOnInit();
        }
      } );
     } );
  }
//Benja was here
acArticulo(){
  if(this.product.valid){
    if(this.product.value.id == null){
      swal2.fire('Atención', 'No existe un artículo con esas características, te recomendamos guardarlo como nuevo', 'info');
      return;
    }
    // console.log(this.product.value.price)
    //this.product.value.price = this.product.value.price.replaceAll(',', '');
    this.product.value.iva_price = this.getFormattedValue(this.product.value.iva_price);
    // console.log(this.getFormattedValue(this.product.value.price));
    // this.product.value.iva_price = this.getFormattedValue((this.product.value.price * 1.16).toString());
    this.catalogosService.actualizaProducto(this.product.value.id, this.product.value).subscribe( np => {
      swal2.fire({
        title: 'Atención',
        text: 'Articulo modificado con exito',
        icon: 'success',
        showConfirmButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Aceptar',
        allowOutsideClick: false
      }). then ( res => {
        if ( res.value ) {
          this.ngOnInit();
          this.mostrarFormulario = false;
        }
        
      } );
      
    }, (err) => {
      swal2.close();
      console.log(err);
      swal2.fire({
        title: 'Ocurrio un error al actualizar',
        text: '',
        icon: 'info',
        showConfirmButton: true,
        showCancelButton: false,
        allowOutsideClick: false
      })
    } );
  }else{
    swal2.fire('Atención', 'Faltan datos', 'info');
  }
}
nArticulo(){
  //this.product.value.price = this.product.value.price.replaceAll(',', '')
  this.product.value.iva_price = this.getFormattedValue(this.product.value.iva_price);
  // this.product.value.iva_price = this.getFormattedValue((this.product.value.price * 1.16).toString());
  this.catalogosService.crearProducto(this.product.value).subscribe( np => {
    swal2.close();
    swal2.fire({
      title: 'Atención',
      text: 'Articulo agregado correctamente',
      icon: 'success',
      showConfirmButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Aceptar',
      allowOutsideClick: false
    }). then ( res => {
        if ( res.value ) {
         /* this.catalogosService.getProductos().subscribe(resp => {
            this.productos = resp;
          })*/
          window.location.reload();
          
        }
      } );

    }, (err) => {
      swal2.close();
      console.log(err);
      swal2.fire({
        title: 'Ocurrio un error',
        text: '',
        icon: 'info',
        showConfirmButton: true,
        showCancelButton: false,
        allowOutsideClick: false
      }). then ( res => {
        if ( res.value ) {
          this.ngOnInit();
        }
      } );
     } );
  }

  // para validar y limitar el número de decimales en tiempo real
  validateInput(event: any) {
    const input = event.target as HTMLInputElement;
    let value = input.value;

    const parts = value.split('.');
    if (parts.length === 2) {
      parts[1] = parts[1].substring(0, 2);
      value = parts.join('.');
    }

    input.value = value;
  }

  // Para cuando pierda el foco formatear
  fmCurrency(event: any) {
    const input = event.target as HTMLInputElement;
    let value = input.value;

    // elimino caracteres no numericos excepto el punto decimal
    value = value.replace(/[^0-9.]/g, '');

    // mantengo solo el primer decimal
    const parts = value.split('.');
    if (parts.length > 2) {
      value = parts[0] + '.' + parts.slice(1).join('');
    }

    // formateo y limito a solo dos decimales
    let [integerPart, decimalPart] = value.split('.');
    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    decimalPart = (decimalPart || '').padEnd(2, '0').substring(0, 2);

    // actualizo visualmente
    input.value = `${integerPart}${decimalPart ? '.' + decimalPart : ''}`;
  }

  getFormattedValue(value: string): string {
    const valuer = value.replace(/,/g, '');
    
    // busco el punto decimal
    const decimalIndex = valuer.indexOf('.');
    
    if (decimalIndex !== -1) {
        // trunco a dos y no redondeo
        return valuer.substring(0, decimalIndex + 3); 
    }
    
    // si no hubiera decimal retorno el valor
    return valuer;
}

  enviarCotizacion(status){
   /* if(this.articulos.length == 0){
      if(!this.product.valid){
        swal2.fire('Atención', 'No hay artículos o está incompleto', 'info');
        return;
      }else{
        return;
        this.agregarArticuloCotizacion();
      }
    }else{
      if(this.product.valid){
        return;
        this.agregarArticuloCotizacion();
      }
    }*/

    if(this.superAdmin && this.company_id == ''){
      swal2.fire('Atención', 'Selecciona una empresa', 'info');
      return;
    }

    if(this.articulos.length <= 0){
        swal2.fire('Atención', 'No hay artículos o está incompleto', 'info');
        return;    
    }

    if(this.plazosSeleccionados.length == 0){
      swal2.fire('Atención', 'Debes seleccionar al menos un plazo', 'info');
      return;
    }

    Swal2.showLoading();

    this.total = 0;
    var articulosPendientes = [];
    this.articulos.forEach(a => {
      articulosPendientes.push({...a});
    })
    articulosPendientes.forEach(a => {
      a.total = a.total.replaceAll(',', '');
      a.iva_price = a.iva_price.replaceAll(',', '');
      var id = a.id;
      a.product_id = id;
      delete a.id;
      this.total = this.total + parseFloat(a.total);
    })

    let date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let completeDate = year + '-' + month + '-' +day;
    var params = {};
    var quote = {
      "date": completeDate,
      "total": this.total,
      "status": status,
      "user_vendor_id": localStorage.getItem('uid'),
    };
    if(this.contacto){
      quote["user_customer_id"] = this.doctorSeleccionado.user_id
      quote["name"] = this.doctorSeleccionado.name
      quote["mail"] = this.doctorSeleccionado.mail
    }else if(this.generico){
      quote["user_customer_id"] = '',
      quote["name"] = 'Quién corresponda',
      quote["mail"] = ''
    }else if(this.input){
      quote["user_customer_id"] = '',
      quote["name"] = (document.getElementById('nombre') as HTMLInputElement).value,
      quote["mail"] = (document.getElementById('correo') as HTMLInputElement).value
    }else{
      swal2.fire('Atención', '¿A quién se dirige la cotización?', 'info');
      return;
    }
    if(!this.contacto){
      params["consultation_fee"] = this.costoConsulta//this.getFormattedValue(this.costoConsulta);
      // params["monthPatients"] = this.pacientesPorMes;
      params["month_patients"] = this.pacientesPorMes;
    }
    var customer_credits = [];
    this.plazosSeleccionados.forEach(p => {
      if (this.getFormattedValue(this.pagoInicial.toString()) === '') {
        this.pagoInicial = 0;
      }
      customer_credits.push(
        { 
          "total_requested": this.total, 
          "status": "PA", 
          "start_date": completeDate,
          "term_id": this.terms.find(t => t.value == p).id, 
          "payment_period_id": this.paymentPeriods.find(p => p.key == 'Mensual').id, 
          // "iva_percent": this.iva,
          "user_id": localStorage.getItem('uid'), 
          "currency": "PESOS", 
          "rate": this.rate ,
          "residual_value": this.valorResidual,
          "inicial_payment": this.getFormattedValue(this.pagoInicial.toString())//this.formatCurrency(this.pagoInicial.toString()).replaceAll(',', '').split('.')[0]//this.getFormattedValue(this.pagoInicial.toString())
          // "inicial_payment": this.pagoInicial.toString().replace(/,/g, '')
        }
      )
    });
    var porcen = '0';
    if(this.porcentaje){
      porcen = '1';
    }
    var primer = 0;
    if(this.primerPago){
      primer = 1;
    }
    params['quote'] = quote;
    params['quote_products'] = articulosPendientes;
    params['customer_credits'] = customer_credits;
    params['is_percentage'] = porcen;
    params['late_payment'] = primer;
    
    params['company_id'] = this.company_id;

    console.log('cotizacion', params);
          
    this.creditosService.customerCredit(params).subscribe(resp => {
      if(status == 'PA'){
        console.log('cotizacion', resp)
        // swal2.fire('Exito', 'Cotización generada con éxito', 'success');
        var url = `Hola! tu cotización está lista: ${resp["attached"]}`;
        Swal2.fire({
          title: "Cotización creada correctamente",
          icon: "success",
          html: `
            <div class="row">
              <div class="col">
                <a title="Descargar" class="btn btn-white btn-icon mb-3 mb-sm-0" href="${resp['attached']}" download>
                    <span class="btn-inner--icon"><i class="material-icons">file_download</i></span>
                </a>
              </div>
              <div class="col">
                <a title="Enviar por correo" class="btn btn-white btn-icon mb-3 mb-sm-0" href="mailto:${quote['mail']}?subject=Envío de cotización&body=${resp['attached']}">
                    <span class="btn-inner--icon"><i class="material-icons">email</i></span>
                </a>
              </div>
              <div class="col">
                <img class="btn-whatsapp" src="https://clientes.dongee.com/whatsapp.png" width="55" height="55" alt="Whatsapp" onclick="window.location.href='https://api.whatsapp.com/send?text=${url}'"/>
              </div>
            </div>

            <div class="row mt-3" style="text-align: center;">
              <input id="url" class="form-control" value="${resp['attached']}" style="width: 80%;">
              <a style="width: 20%;" class="btn btn-white btn-icon mb-3 mb-sm-0" onclick="navigator.clipboard.writeText('${resp['attached']}')">
                  <span class="btn-inner--icon"><i class="material-icons">content_copy</i></span>
              </a>
            </div>
          `,
          showCloseButton: true,
          showCancelButton: false,
          allowOutsideClick: false
        }).then(res => {
          if(res.value){
            Swal2.close();
            location.reload();
            this.ngOnInit();
          }
        });
        
      }else{
        Swal2.close();
        console.log('SI', resp);
        this.simulacion = [];
        resp['customer_credits'].forEach(cc => {
          cc['total_requested_iva'] = (parseFloat(cc['total_requested']) * 1.16).toString();
          this.simulacion.push(cc);
          this.simulacion = this.simulacion.sort(((a, b) => a.term.value - b.term.value));
        });
      }
    }, err =>{
      console.log('error', err);
      try{
        swal2.fire('Atención', err.error.errors[0], 'info');
      }
      catch{
        swal2.fire('Atención', 'Algo salió mal', 'info');
      }
    })
  }

  

}
