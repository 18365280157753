import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Usuario, Usuario2 } from 'src/app/models/usuario.model';

@Injectable({
  providedIn: 'root'
})
export class ReportesService {

  token: string;
  idUsuario: string;
  path: string =  environment.URL_SERVICIOS.replace('/api/v1','');

  constructor(
    public http: HttpClient
  ) {
    this.cargarStorage();
  }

  cargarStorage() {

    if ( localStorage.getItem('tkn')) {
      this.token = localStorage.getItem('tkn');
      this.idUsuario = localStorage.getItem('uid');
    } else {
      this.token = '';
      this.idUsuario = '';
    }

  }

  getBase( date, currency ) {

    const url = `${environment.URL_SERVICIOS}/reports/start_date/${date}/currency/${currency}/layout_base?token=${this.token}&secret_key=${environment.SECRET_KEY}`;

    return this.http.get(url).pipe(
      map( (resp: any) => {
        return resp;
      } )
    );

  }

  getBanorte( date, currency ) {

    const url = `${environment.URL_SERVICIOS}/reports/start_date/${date}/layout_banorte?token=${this.token}&secret_key=${environment.SECRET_KEY}`;

    return this.http.get(url).pipe(
      map( (resp: any) => {
        return resp;
      } )
    );

  }

  getReporteConsultasBuro( date1, date2 ) {

    const url = `${environment.URL_SERVICIOS}/buroConsultsReport?fecha_inicio=${date1}&fecha_fin=${date2}&token=${this.token}&secret_key=${environment.SECRET_KEY}`;

    return this.http.get(url).pipe(
      map( (resp: any) => {
        return resp;
      } )
    );

  }

  getRegistrationInfo( id ) {

    const url = `${environment.URL_SERVICIOS}/reports/customer_credit_id/${id}/get_customer?token=${this.token}&secret_key=${environment.SECRET_KEY}`;

    return this.http.get(url).pipe(
      map( (resp: any) => {
        return resp;
      } )
    );

  }

  getRegistrationInfo2( data ) {
    const url = `${this.path}/reports/customer_credit_id/${data.id}/get_customer`;

    return this.http.get(url).pipe(
      map( (resp: any) => {
        return resp;
      } )
    );

  }

  financialWorkers() {
      const url = `${environment.URL_SERVICIOS}/reports/job/FINANCIERA/financial_workers?token=${this.token}&secret_key=${environment.SECRET_KEY}`;
      return this.http.get(url)
    
    }

  userRequest() {
      const url = `${environment.URL_SERVICIOS}/reports/user_id/${this.idUsuario}/user_requests?token=${this.token}&secret_key=${environment.SECRET_KEY}`;
      return this.http.get(url)
    
    }

  allQuotes() {
      const url = `${environment.URL_SERVICIOS}/get_all_quotes?token=${this.token}&secret_key=${environment.SECRET_KEY}`;
      return this.http.get(url)
    
    }

  allPaQuotes() {
      const url = `${environment.URL_SERVICIOS}/get_pa_quotes?token=${this.token}&secret_key=${environment.SECRET_KEY}`;
      return this.http.get(url)
    
    }
}
