import Swal from 'sweetalert2';
import { Component, OnInit } from '@angular/core';
import { AwsService, CreditosService } from '../../services/service.index';
import { Router, ActivatedRoute } from '@angular/router';
import { UsuarioService } from '../../services/usuario/usuario.service';
import swal2 from 'sweetalert2';
import { RolesService } from '../../services/roles/roles.service';
import { ListasService } from '../../services/listas/listas.service';
import { info } from 'console';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-solicitudcredito',
  templateUrl: './solicitudcredito.component.html',
  styleUrls: ['./maincliente.component.css']
})
export class SolicitudcreditoComponent implements OnInit {
  uid;
  showModalAmortizacion = false;
  cols = [];
  cols_products = [];
  customer_credit = {id: '', quote_id: ''};
  cotizaciones = [];
  showmodaldsDetalle = false;
  cotizacion = {};
  quote = {};
  amortizacion = [];
  showmodalFormulario = false;
  lists = [];
  fiscal_regime: any[];
  address_type: any[];
  suburb_type: any[];
  martial_status: any[];
  houseType: any[];
  seniority: any[];
  company_rate: any[];
  weekday: any[];
  countries: any;
  legal_entity = new FormGroup({
    fiscal_regime: new FormControl(),
    // rfc: new FormControl(),
    rug: new FormControl(),
    business_name: new FormControl(),
    phone: new FormControl(),
    mobile: new FormControl(),
    // email: new FormControl('', [Validators.required, Validators.email]),
    business_email: new FormControl('', [Validators.required, Validators.email]),
    main_activity: new FormControl(),
    // fiel: new FormControl(),
  })

  person = new FormGroup({
    fiscal_regime: new FormControl(), 
    // rfc: new FormControl(), 
    curp: new FormControl(), 
    imss: new FormControl(),   
    first_name: new FormControl(), 
    last_name: new FormControl(), 
    second_last_name: new FormControl(), 
    gender: new FormControl(),    
    nationality: new FormControl(), 
    birth_country: new FormControl(), 
    birthplace: new FormControl(), 
    birthdate: new FormControl(),  
    id_type: new FormControl(), 
    identification: new FormControl(), 
    phone: new FormControl(),
    mobile: new FormControl(), 
    // email: new FormControl('', [Validators.required, Validators.email]),
  })
  
  contributor_address = new FormGroup({
    municipality_id: new FormControl(),
    state_id: new FormControl(),
    address_type: new FormControl(),
    street: new FormControl(),
    external_number: new FormControl(),
    apartment_number: new FormControl(),
    postal_code: new FormControl(),
    address_reference: new FormControl(),
    suburb: new FormControl(),
  })
  
  
  contributor = new FormGroup({
    contributor_type: new FormControl(),
    bank: new FormControl(),
    account_number: new FormControl(),
    clabe: new FormControl(),
    extra1 :new FormControl(),
  })

  legal_entity_group = this._formBuilder.group({});
  contributor_address_group = this._formBuilder.group({});
  contributor_group = this._formBuilder.group({});
  fiscal = '';
  states: any[];
  municipies: any[];
  colonias = [];
  capturaCp = false;
  cotizacionesAprobadas = [];
  showModalNip = false;
  bank: any[];
  customer = false;
  showModalTerminosYCondiciones = false;
  proceder = false;
  customer_id: any;


  constructor( private creditosService: CreditosService,
                public router: Router,
                private listasService: ListasService,
                private aws: AwsService,
                private _formBuilder: FormBuilder,
                private usuarioService: UsuarioService  ) { }

  ngOnInit(): void {

    this.listasService.getLists().subscribe(lists => {
      lists['data'].forEach(list => {
        this.lists.push(list.attributes);
      });
    this.fiscal_regime = this.lists.filter(p => p.domain === 'CONTRIBUTOR_FISCAL_REGIME');
    this.address_type = this.lists.filter(p => p.domain === 'CONTRIBUTOR_ADDRESS_ADDRESS_TYPE');
    this.suburb_type = this.lists.filter(p => p.domain === 'CONTRIBUTOR_ADDRESS_SUBURB_TYPE');
    this.martial_status = this.lists.filter(p => p.domain === 'PERSON_MARTIAL_STATUS');
    this.houseType = this.lists.filter(p => p.domain === 'HOUSE_TYPE');
    this.seniority = this.lists.filter(p => p.domain === 'SENIORITY');
    this.company_rate = this.lists.filter(p => p.domain === 'COMPANY_RATE');
    this.weekday = this.lists.filter(p => p.domain === 'WEEKDAY');
    this.bank = this.lists.filter(p => p.domain === 'PERSON_BANK');
    })
    this.aws.getCountriesD().subscribe( resp => { this.countries = resp; } );

    this.uid = localStorage.getItem('uid');
    this.cotizaciones = [];
    this.creditosService.getUserCotizaciones(this.uid).subscribe(resp => {
      this.cotizaciones = [];
      this.cotizacionesAprobadas = [];
      this.cotizaciones = Object.values(resp).filter(c => c.status == 'PA');
      this.cotizaciones.forEach(c => {
        c['total'] = (parseFloat(c['total']) * 1.16).toString();
      })
      this.cotizacionesAprobadas = Object.values(resp).filter(c => c.status == 'AP')
      if(this.cotizaciones.length > 0 || this.cotizacionesAprobadas.length > 0){
        this.cols = [

          { field: 'date', header: 'Fecha'},
          { field: 'products', header: 'Productos'},
          { field: 'total', header: 'Total'},
          { field: 'vendor', header: 'Vendedor'},
          { field: 'vendor_email', header: 'Correo electrónico vendedor'},
        ];
        this.cols_products = [
          {field: 'product_key', header: 'Clave producto'},
          {field: 'product_number', header: 'Número producto'},
          {field: 'product_type', header: 'Tipo producto'},
          {field: 'short_description', header: 'Descripción corta'},
          {field: 'brand', header: 'Marca'},
          {field: 'model', header: 'Modelo'},
          {field: 'iva_price', header: 'Valor unitario'},
          {field: 'quantity', header: 'Cantidad'},
        ]

        if(this.cotizaciones.length > 0){
          this.usuarioService.getusuarioById(this.uid).subscribe(resp => {
            if(resp.data.relations.customers.length > 0){
              this.customer = true;
              this.customer_id = resp.data.relations.customers[0].id;
              this.usuarioService.getBuroById(this.uid).subscribe(resp => {
                // si es true, es que no tiene buro y hay que pedir el nip...
                if(resp.status){
                  this.showModalTerminosYCondiciones = true;

                }
              })
            }
          })
        }
      }else{
        swal2.fire('Atención', 'No hay cotizaciones para este usuario');
      }
    })

    
  }

  modalBuscarCotizacion(){
    type LoginFormResult = {
      quote_folio: string
    }
    
    let quoteInput: HTMLInputElement
    
    swal2.fire({
      title: 'Capturar folio de cotización',
      html: `
        <input type="text" id="folio" class="swal2-input" placeholder="Folio">
      `,
      confirmButtonText: 'Enviar',
      focusConfirm: false,
      didOpen: () => {
        const popup = swal2.getPopup()!
        quoteInput = popup.querySelector('#folio') as HTMLInputElement
        quoteInput.onkeyup = (event) => event.key === 'Enter' && swal2.clickConfirm()
      },
      preConfirm: () => {
        const quote_folio = quoteInput.value
        if (!quote_folio) {
          swal2.showValidationMessage(`Porfavor ingresa un folio válido`)
          return
        }
        this.asignarQuote(quote_folio);
      },
    })
  }


  asignarQuote(quote_folio){
    if(quote_folio == ''){
      swal2.fire('Atención', 'Es necesario teclear un número de folio', 'info');
      return;
    }
    var params = {
      quote: {
        quote_folio: quote_folio, 
        user_customer_id: this.uid
      }
    }
    this.creditosService.clienteAsignaCotizacion(params).subscribe(resp => {
      console.log(resp);
      swal2.fire('Éxito', 'Cotización asignada correctamente', 'info');
      window.location.reload();
    }, err => {
      console.log(err);
      try{
        swal2.fire('Atención', err['error']['errors'][0], 'info');
      }catch{
        swal2.fire('Atención', 'Algo salió mal', 'info');
      }
    })
  }

  modal(rowData){
    this.quote = {};
    this.showmodaldsDetalle = true;
    this.creditosService.getCotizacionById(rowData.id).subscribe(resp => {
      this.quote = resp[0];
      this.quote['customer_credits'] = this.quote['customer_credits'].sort(((a, b) => a.term.value - b.term.value));
    })
  }

  show(tabla) {
    this.amortizacion = [];
    this.amortizacion = tabla.sort(((a, b) => a.pay_number - b.pay_number));
    this.showModalAmortizacion = true; // Show-Hide Modal Check
    this.showmodaldsDetalle = false
  }

  hideds(){
    this.showmodaldsDetalle = false;
    this.cotizacion = {};
  }

  hideModal() {
    this.showModalAmortizacion = false;
    this.showmodaldsDetalle = true;
  }  

  mostrarTerminos(){
    this.showModalTerminosYCondiciones = true;
  }

  validarTerminos(){
    this.showModalTerminosYCondiciones = false;
    this.showModalNip = true;
 }

  hidef(){
    this.showmodalFormulario = false;
    this.showmodaldsDetalle = true;
  }

  buscaEdo(id) {
    this.aws.getStatesId(id).subscribe( r => {this.states = r;} )
   }
 
   buscaMun(id) {
    this.aws.getMunicipios(id).subscribe( r => {this.municipies = r;} )
   }

   getColonias(event) {
    this.aws.getColoniasxcp(event).subscribe(r => {
       this.colonias = r;
       this.capturaCp = false;
       if(this.colonias.length == 0){
         this.capturaCp = true;
       }
     })
   }

   evaluarRFC(){
    console.log(this.legal_entity.value)
    if(this.legal_entity.value.rfc.length == 13){
      console.log('PF')
    }else if(this.legal_entity.value.rfc.length == 12){
      console.log('PM')
    }else{
      swal2.fire('Atención', 'RFC incorrecto', 'info');
      return;
    }
  }

  seleccionar(seleccion){
    console.log(seleccion);
    this.customer_credit = {id: '', quote_id: ''};
    this.customer_credit = seleccion;
    if(this.customer){
      this.showmodaldsDetalle = false;
      this.mostrarNip();
    }else{
      this.showmodalFormulario = true;
      this.showmodaldsDetalle = false;
    }
  
  }

  mostrarNip(){
    this.showModalNip = true;
    this.showmodalFormulario = false;
  }

  validarNip(){
    /* alert("revisar porque originalmente se mandaba el customer, tambien donde se recupera el nip");
    this.showModalNip = false;
    this.registrar(); */
     // VALIDA EL NIP POR PRIMERA VEZ Y MUESTRA 
    var nip = (document.getElementById('nip') as HTMLInputElement).value;
    // ENVIA EL CUSTOMER ID
    this.usuarioService.getNipStatus(nip,this.uid).subscribe( resp => {
      if ( resp.status !== true ) {
        swal2.fire(
        'El NIP es incorrecto',
        'Intente de nuevo',
        'error'
        
     ).then(okay => {
      // if (okay) {
        swal2.close();
      //  this.focusNip();
    //  }
   });
      this.showModalNip = true;
      // this.nipValidado = false;
      (document.getElementById('nip') as HTMLInputElement).value = ''

    } else {
      swal2.fire('Éxito', 'NIP validado correctamente', 'success');
          this.showModalNip = false;
          if(this.proceder){
            this.registrar();
          }else{
            this.proceder = true;
          }
          }
      }
      );  
  }

  registrar(){
    var params = { 
      quote_id: this.customer_credit.quote_id,
      customer_credit_id: this.customer_credit.id,
      status: 'AP',
      customer_id: this.customer_id
    }

    /* if(!customer){
      this.contributor.value.contributor_type = this.fiscal;
      params['contributor'] = this.contributor.value;

      params['contributor_address'] = this.contributor_address.value;

      if(this.fiscal == 'PF'){
        this.person.value.fiscal_regime = this.fiscal;
        params['person'] = this.person.value;
      }else if(this.fiscal == 'PM'){
        this.legal_entity.value.fiscal_regime = this.fiscal;
        params['legal_entity'] = this.legal_entity.value;
      }

      var customer = { 
        customer_type: this.fiscal,
        status: "AC",
        user_id: this.uid //usuario firmado
      }
      params['customer'] = customer;
    }else{
      var cus = {
        user_id: this.uid
      }
      params['customer'] = cus
    } */
    
    console.log(params);
    this.creditosService.aceptarCustomerCredit(params).subscribe(resp => {
      console.log(resp)
      Swal.fire({
        title: 'Éxito',
        html: `Cotización aceptada correctamente, nos comunicaremos pronto con usted.`,
        icon: 'success',
        showCancelButton: false,
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.showModalAmortizacion = false;
          this.showmodalFormulario = false;
          this.showmodaldsDetalle = false
          location.reload();
          this.ngOnInit();
        }
      })
    }, err => {
      console.log(err);
    })
  }

  resetNip(){
    // SERVICIO PARA GENERAR NUEVO NIP
    this.usuarioService.resetNip(this.uid).subscribe( resp => {
      console.log(resp);
      if ( resp.status !== true ) {
        swal2.fire(
        'Ha ocurrido un error',
        'Vuelva a intentarlo',
        'error'
     );
    } else {
      swal2.fire('Se le ha enviado un nuevo NIP', 'Revise su correo', 'success');
          }
      }, err => {
        console.log(err);
      }
      );  
  }



}
