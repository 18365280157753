import { Component, OnInit } from '@angular/core';
import { CatalogosService } from 'src/app/services/catalogos/catalogos.service';
import { CreditosService } from 'src/app/services/service.index';
import swal2 from 'sweetalert2';
import Swal2 from 'sweetalert2';

@Component({
  selector: 'app-revisar-cotizaciones',
  templateUrl: './revisar-cotizaciones.component.html',
  styles: [
  ]
})
export class RevisarCotizacionesComponent implements OnInit {
  uid: any;
  cols: { field: string; header: string }[];
  cotizaciones: Object;
  filtro = 'PA';
  quote = {};
  showmodaldsDetalle: boolean;
  cols_products: { field: string; header: string; }[];
  amortizacion: any[];
  showModalAmortizacion = false;
  doctores = [];

  constructor(private creditosService: CreditosService,
              private catalogosService: CatalogosService) { }

  ngOnInit(): void {
    this.uid = localStorage.getItem('uid');
    this.catalogosService.getDoctoresPorVendedor(localStorage.getItem('uid')).subscribe(resp => {
      this.doctores = resp;
    })
    this.creditosService.getVendorCotizaciones(this.uid).subscribe(resp => {
      /* Object.values(this.cotizaciones['data']).forEach(credit => {
        console.log(credit['attributes'].status)
      }); */
      this.cotizaciones = Object.values(resp).filter(c => c.status == this.filtro)
      console.log(this.cotizaciones)
      Object.values(this.cotizaciones).forEach(c => {
        c['total'] = (parseFloat(c['total']) * 1.16).toString();
        if(!c.user_customer_id){
          var user_customer = {name: 'Sin asignar', email: ''};
          c.user_customer = user_customer
        }
      });
      /* Object.keys(credits['data']).map(key => {
        let credit = credits['data'][key].attributes;
        if(this.filtro == 'AP'){
          if(credit['status'] === 'AP'){
            this.armarCreditos(credit);
          }
        }else if(this.filtro == 'NO'){
          if(credit['status'] === 'NO'){
            this.armarCreditos(credit)
          }
        }
      }) */
    });
    
    this.cols = [

      { field: 'date', header: 'Fecha' },
      // { field: 'status', header: 'Estado' },
      { field: 'total', header: 'Total' },
      { field: 'products', header: 'Productos' },
      { field: 'name', header: 'Nombre cliente' },
      { field: 'email', header: 'Correo cliente' },
      { field: 'attached', header: 'Cotización' },

    ];

    this.cols_products = [
      {field: 'product_key', header: 'Clave producto'},
      {field: 'product_number', header: 'Número producto'},
      {field: 'product_type', header: 'Tipo producto'},
      {field: 'short_description', header: 'Descripción corta'},
      {field: 'brand', header: 'Marca'},
      {field: 'model', header: 'Modelo'},
      {field: 'iva_price', header: 'Precio con IVA'},
    ]
  }

  modal(rowData){
    this.quote = {};
    this.showmodaldsDetalle = true;
    this.creditosService.getCotizacionById(rowData.id).subscribe(resp => {
      this.quote = resp[0];
      console.log(this.quote);
    })
  }

  hideds(){
    this.showmodaldsDetalle = false;
    this.quote = {};
  }

  show(tabla) {
    this.amortizacion = [];
    this.amortizacion = tabla;
    this.showModalAmortizacion = true; // Show-Hide Modal Check
    this.showmodaldsDetalle = false
  }

  hideModal() {
    this.showModalAmortizacion = false;
    this.showmodaldsDetalle = true;
  }

  cambiarFiltro(status){
    this.filtro = status;
    setTimeout(() => {
      document.getElementById('reset').click();
    }, 1800);
    this.ngOnInit();
  }

  enviarCorreo(id){
    console.log(id);
    type LoginFormResult = {
      nombre: string
      correo: string
    }
    
    let nombreInput: HTMLInputElement
    let correoInput: HTMLInputElement
    
    Swal2.fire({
      title: 'Enviar cotización',
      html: `
        <input type="text" id="nombre" class="swal2-input" placeholder="Nombre">
        <input type="email" id="correo" class="swal2-input" placeholder="Correo">
      `,
      confirmButtonText: 'Enviar',
      focusConfirm: false,
      didOpen: () => {
        const popup = Swal2.getPopup()!
        nombreInput = popup.querySelector('#nombre') as HTMLInputElement
        correoInput = popup.querySelector('#correo') as HTMLInputElement
        nombreInput.onkeyup = (event) => event.key === 'Enter' && Swal2.clickConfirm()
        correoInput.onkeyup = (event) => event.key === 'Enter' && Swal2.clickConfirm()
      },
      preConfirm: () => {
        const nombre = nombreInput.value
        const correo = correoInput.value
        if (!nombre || !correo) {
          Swal2.showValidationMessage(`Porfavor ingresa nombre y correo`)
          return
        }
        this.creditosService.enviarCotizacion(id, nombre, correo).subscribe(resp => {
          console.log(resp);
          Swal2.fire('Éxito', 'Cotización enviada correctamente', 'success');
        }, err => {
          console.log(err);
          try{
            Swal2.fire('Atención', 'Algo salió mal', 'info');
          }
          catch{
            Swal2.fire('Atención', 'Algo salió mal', 'info');
          }
        })
      },
    })
  }

  seleccionarMedico(medico, id){
    if(medico == 'NA'){
      return;
    }
    medico = this.doctores.find(m => m.user_id == medico)
    var params = {};
    var quote = {user_customer_id: medico.user_id};
    params['quote'] = quote
    console.log(params)
    swal2.fire({
      title: 'Atención',
      html: `¿Está seguro?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Asignar',
      cancelButtonText: 'Cancelar',
      allowOutsideClick: false
    }).then((result) => {
      if (result.isConfirmed) {
        swal2.fire({
          title: 'Actualizando médico',
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          onOpen: () => {
              swal2.showLoading()
          }
      })
        this.creditosService.updateQuote(id, params).subscribe(resp => {
          console.log(resp, id);
          swal2.fire('Éxito', 'Médico actualizado correctamente', 'success');
          this.ngOnInit();
          location.reload();
        }, err =>{
          console.log(err);
          swal2.fire('Atención', 'Algo salió mal', 'info');
        })
      }
    })
  }

}
